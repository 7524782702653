import ReactTypingEffect from 'react-typing-effect';
import workWith from '../../data/workWith.json'
/*--------------------
* Banner Section
----------------------*/
export default function Banner() {


    const workWithArray = [];
    workWith.map((data) => (
        workWithArray.push(data.title)
    ))

    return (
        <>
            <section data-scroll-data="0" id="home" className="home-section pt-[120px] pb-[80px] lg:pt-[180px] lg:pb-[100px] bg-orange-50 relative overflow-hidden">
                <div className="container relative z-[1] w-10/12">
                    <div className="grid lg:grid-cols-12 grid-cols-1 items-center">
                        <div className="lg:col-span-6">
                            <div className="text-center lg:text-start mb-[50px] lg:mb-0">
                                <h6 className="text-black uppercase text-[14px] md:text-[16px] tracking-[2px] font-[600] mb-[20px] md:mb-[30px]">Hello <span className='wave'>👋</span>, My name is Veronika</h6>
                                <h1 className="text-black font-[600] text-[40px] lg:text-[45px] xl:text-[50px] leading-[1] mb-[25px] md:mb-[35px]">I work with 
                                    <span className='block h-24' id="type-it"><ReactTypingEffect text={workWithArray}
                                        speed="100" typingDelay="300" eraseDelay="300" eraseSpeed="100" />
                                    </span>
                                </h1>
                                <p className="text-[16px] md:text-[20px] mb-[8px] md:mb-[16px]">Based in Bulgaria.</p>
                                <div className="pt-[10px]">
                                    <a className="px-btn px-btn-theme" download href="cv/Veronika-Licheva-CV.pdf">Download CV 📑</a>
                                </div>
                            </div>
                        </div>
                        <div className="lg:col-span-6">
                            <div className="home-image text-center">
                                <img src="img/home-banner-ver.2.png" title="Banner" alt="Banner" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
