import Banner from "../Banner/banner"
import BrandSlider from "../Brand/brand"
import About from "../About/about"
import ServiceList from "../Service/service"
import SkillList from "../Skills/skill"
import Work from "../Work/work"
import Contact from "../Contact/contact"
import Testimonial from "../Testimonial/testimonial"
import Intrested from "../Intrested/intrested"
import SocialMediaBanner from "../SocialMedia/brand"
import Photography from "../Photography/photography"
import { WaveTopBig, WaveBottom, TriangleFlip, Triangle, WaveTopOpacity} from "../Dividers/shapes"

/*--------------------
* Building Veronika
----------------------*/
export default function Veronika() {
    return (
        <>
            <Banner/>
            <BrandSlider/>
            <WaveTopOpacity />
            <About/>
            <ServiceList/>
            <Intrested />
            <SkillList/>
            <WaveBottom />
            <SocialMediaBanner/>
            <WaveTopBig />
            <Work/>
            <Triangle />
            <Testimonial/>
            <TriangleFlip />
            <Photography/>
            <Contact/>
        </>
    )
}
