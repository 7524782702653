

export default function TextTitleSection({ title, text }) {
    return (
        <div className="xl:my-28 lg:my-14 my-6 grid grid-cols-12 gx-3 portfolio-box justify-around items-center">
            <div className="col-span-12">
                <div className="portfolio-text mb-6">
                    <div className="font-black text-3xl mb-3 xl:text-5xl">{title}</div>
                    <p className="text-lg xl:text-2xl">{text}</p>
                </div>
            </div>
        </div>
    );
}


