import AihrData from "../../data/projects/aihr.json"
import Title from "../../components/ProjectPages/Title"
import Section from "../../components/ProjectPages/Section"
import SocialLinks from "../../components/ProjectPages/SocialLinks";
import ImagesSection from "../../components/ProjectPages/ImagesSection";
import TextImageSection from "../../components/ProjectPages/TextImageSection";
import TextTitleSection from "../../components/ProjectPages/TextTitleSection";
import FactCard from "../../components/ProjectPages/FactCard";
/*--------------------
* Building AIHR
----------------------*/
export default function AIHR() {
    var data = JSON.parse(JSON.stringify(AihrData));
    data = data[0];
    return (
        <div className="bg-orange-50 h-full pt-[65px] pb-[69px] px-4 md:px-auto w-full text-slate-900">
            <div className="md:w-3/4 w-full mx-auto">
                <Title title={data.job_title + " at " + data.company + "!"} />
                <Section title="Quick intro" content={data.intro}/>
                <div className="text-center my-6">
                    {
                        data.social_links.map((val, i)=>{
                            return <SocialLinks key={i} link={val.link} icon={val.icon}/>
                        })
                    }
                </div>
                <Section className="mt-20" title={data.engagement[0].title} content={data.engagement[0].text}/>
                <TextImageSection 
                    title="" 
                    text={[<>
                        <span className="block">HR professional or miracle worker? 🤔 Well, one thing is for sure - HR is always on top of it all. Here's to keeping the peace and solving problems the rest of the company is oblivious to 🙌</span>
                        <span className="block"><br />#HR #HumanResources #HRmanagement</span>
                        <br /><a href="https://www.linkedin.com/feed/update/urn:li:activity:6768891969692278785/" target="_blank" className="px-btn px-btn-theme">Full post and engagement</a>
                    </>]}
                    imageSrc="img/projects/AIHR/1654952400335.jpeg"
                    small={true}
                    transparent={true}
                    frameless={true}
                />
                <TextImageSection 
                    title="" 
                    text={[<>
                        <span className="block">Success is all about growing others 🌱 Hiring the right people for the right role is the most important job of a manager. But what is more important, is helping them grow and navigating them toward success.</span>
                        <span className="block"><br />#HR #HumanResources #HRmanagement</span>
                        <br /><a href="https://www.linkedin.com/feed/update/urn:li:activity:6768891969692278785/" target="_blank" className="px-btn px-btn-theme">Full post and engagement</a>
                    </>]}
                    imageSrc="img/projects/AIHR/1613829600206.jpeg"
                    small={true}
                    left={false}
                    frameless={true}
                />
                <TextImageSection 
                    title="" 
                    text={[<>
                        <span className="block">Have your colleagues ever wondered what an HR manager actually does? How much time do they have? 😉 Check out the tasks and tell us what we missed. Let us know in the comments 👇</span>
                        <span className="block"><br />#HumanResources #HRmanager #HRmanagement</span>
                        <br /><a href="https://www.linkedin.com/posts/aihr_humanresources-hrmanager-hrmanagement-activity-6852588469873725440-LGE0/?utm_source=share&utm_medium=member_desktop" target="_blank" className="px-btn px-btn-theme">Full post and engagement</a>
                    </>]}
                    imageSrc="img/projects/AIHR/1670076000385.jpeg"
                    small={true}
                    frameless={true}
                />
                <ImagesSection> 
                    {
                        data.carousels_images.map((val, i)=>{
                            return <img key={i} src={val} title="image" alt="image" />
                        })
                    }
                </ImagesSection>
                <TextImageSection 
                    title={data.thumbnails[0].title} 
                    text={data.thumbnails[0].text}
                    imageSrc="img/projects/AIHR/fdsa.jpeg"
                    small={true}
                    frameless={true}
                />
                <ImagesSection> 
                    {
                        data.carousels_images_2.map((val, i)=>{
                            return <img key={i} src={val} title="image" alt="image" />
                        })
                    }
                </ImagesSection>
                <TextTitleSection title={data.guides[0].title} text={data.guides[0].text}/>
                <ImagesSection> 
                    {
                        data.carousels_images_3.map((val, i)=>{
                            return <img key={i} src={val} title="image" alt="image" />
                        })
                    }
                </ImagesSection>
                <TextImageSection 
                    title="Growth" 
                    text={[<>
                        <span className="block">My time at AIHR was incredibly rewarding and provided me with valuable experience in the social media and digital marketing world. I am proud of the work I accomplished and the growth that I was able to bring to the company's social media presence.</span>
                        <span className="block"><br />I employed innovative tactics to drive organic growth across social media platforms at AIHR, increasing LinkedIn followers to 180% and website traffic to 379%.</span>
                        <span className="block"><br />My strategies resulted in key performance metrics being hit and engagement significantly rising.</span>
                    </>]}
                    imageSrc="img/projects/AIHR/AF_253.png"
                    frameless={true}
                />
                <div className="flex flex-col md:flex-row gap-2 justify-center my-20">
                    <div className="flex flex-col gap-2">
                        <img className="mix-blend-multiply" src="img/projects/AIHR/AIHR-new-followers.png" title="Increase in LinkedIn followers." alt="Increase in LinkedIn followers." />
                        <span className="text-center pt-[14px]">Increase in LinkedIn followers.</span>
                    </div>
                    <div className="flex flex-col gap-2 pt-[14px]">
                        <img className="mix-blend-multiply" src="img/projects/AIHR/AIHR-website-traffic.png" title="Increase in website traffic." alt="Increase in LinkedIn followers." />
                        <span className="text-center">Increase in LinkedIn followers.</span>
                    </div>
                </div>
                <FactCard data={data.stats} />
            </div>
        </div>
    )
}
