import Subtitle from "./Subtitle";

export default function Section({ title, content, className }) {
    return (
        <div className={"text-gray-900 p-6 my-6 text-center " + className}>
            <Subtitle title={title} />
            <p className="text-[16px] md:text-xl mt-6">
                {content}
            </p>
        </div>
    );
}
