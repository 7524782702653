import { useState } from "react";
import { Link } from "react-scroll";
import projectsData from "../../data/projects.json";
import WorkNomads from "../../pages/projects/WorkNomads";
import LearnWorlds from "../../pages/projects/LearnWorlds";
import AIHR from "../../pages/projects/AIHR";
import ChallengeSchool from "../../pages/projects/ChallengeSchool";
import DutchReview from "../../pages/projects/DutchReview";
import InsightTV from "../../pages/projects/InsightTV";
/*--------------------
* Project Section
----------------------*/

function Project({closePopup, company}) {
    const components = {
        WorkNomads: WorkNomads,
        LearnWorlds: LearnWorlds,
        InsightTV: InsightTV,
        DutchReview: DutchReview,
        ChallengeSchool: ChallengeSchool,
        AIHR: AIHR
    };

    const SpecificExperience = components[company];

    const handleContentClick = (e) => {
        // Stop event propagation to prevent closing the modal
        e.stopPropagation();
    };

    return (
        <>
            <div className="px-modal" onClick={closePopup}>
                <div className="single-project-box overflow-x-hidden rounded-md" onClick={handleContentClick}>
                    <SpecificExperience />
                    <button className="px-close" onClick={closePopup}><i className="fa fa-times"></i></button>
                </div>
            </div>
        </>
    );


}


/*--------------------
* Work Section
----------------------*/

export default function Work() {
    const [showModal, setShowModal] = useState(0);
    const [company, setCompany] = useState("");
    const closePopup = () => {
        setShowModal(0);
    }

    const triggerModal = (company) => {
        setCompany(company);
        setShowModal(1); 
    }

    document.onkeydown = function(evt) {
        evt = evt || window.event;
        if (evt.keyCode === 27) {
            closePopup();
        }
    };

    return (
        <>
            <section data-scroll-data="4" id="portfolio" className="section bg-orange-50">
                <div className="container">
                    <div className="grid section-heading">
                        <div className="lg:col-span-6 text-center mx-auto">
                            <h3><span>Latest Projects</span></h3>
                        </div>
                    </div>
                    <div className="lightbox-gallery portfolio-box">
                    {projectsData.map((val, i) => {
                        return (
                            <div key={i} className="grid grid-cols-12 gx-3 mb-5 pb-lg-3 mb-lg-3 pb-12 portfolio-box justify-around items-center">
                                <div className={ (i % 2 !== 0 ? "md:order-last" : "") + " col-span-12 md:col-span-6 md:px-5 lg:px-10 mb-10 md:mb-0"}>
                                    <div className="portfolio-img">
                                        <img src={"img/projects/" + val.company + "/thumbnail.png"} title={val.company} alt="" />
                                        <Link to="#" className="gallery-link gallery-link-icon">
                                            <i className="fas fa-arrow-right"></i>
                                        </Link>
                                    </div>
                                </div>
                                <div className="col-span-12 md:col-span-6 md:px-5 lg:px-10">
                                    <div className="portfolio-text">
                                        <span className="inline-block px-[20px] py-[8px] bg-orange-200 text-slate-900 tracking-wider uppercase text-[14px] rounded-[30px] mb-4">
                                            {val.date}
                                        </span>
                                        <h4>{val.job_title + " for " + val.company}</h4>
                                        <p>{val.description}</p>
                                        <div className="btn-bar">
                                            <div className="px-btn px-btn-theme px_modal" onClick={() => triggerModal(val.component)}>View Project </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )
                        
                    })}
                    </div>
                </div>
            </section>

            {showModal ? (
                <>
                    <Project closePopup={closePopup} company={company}></Project>
                </>
            ): null}
        </>
    );
}
