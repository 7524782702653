import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default function Icon (props) {
    return (
        <>
            <FontAwesomeIcon
                className={props.className}
                icon={[props.iconStyle, props.icon]}
                size={props.size}
                rotation={props.rotation}
                spin={props.spin}
                border={props.border}
                pull={props.pull}
                flip={props.flip}
            />
        </>
    );
}