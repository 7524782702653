import DutchReviewData from "../../data/projects/dutchReview.json"
import Title from "../../components/ProjectPages/Title"
import Section from "../../components/ProjectPages/Section"
import SocialLinks from "../../components/ProjectPages/SocialLinks";
import ImagesSection from "../../components/ProjectPages/ImagesSection";
import TextImageSection from "../../components/ProjectPages/TextImageSection";
import TextTitleSection from "../../components/ProjectPages/TextTitleSection";
import FactCard from "../../components/ProjectPages/FactCard";
/*--------------------
* Building AIHR
----------------------*/
export default function AIHR() {
    var data = JSON.parse(JSON.stringify(DutchReviewData));
    data = data[0];
    return (
        <div className="bg-orange-50 h-full pt-[65px] pb-[69px] px-4 md:px-auto w-full">
            <div className="md:w-3/4 w-full mx-auto">
                <Title title={data.job_title + " at " + data.company + "!"} />
                <Section title="Quick intro" content={data.intro}/>
                <div className="text-center my-6">
                    {
                        data.social_links.map((val, i)=>{
                            return <SocialLinks key={i} link={val.link} icon={val.icon}/>
                        })
                    }
                </div>
                <TextImageSection 
                    title={data.video_projects[0].title}
                    text={data.video_projects[0].text}
                    imageSrc="https://www.youtube.com/embed/dhKz8TKg-so?si=Mi_PfNveLsRL2n_w"
                    small={true}
                    video={true}
                    transparent={true}
                    frameless={true}
                />
                
                <div className="grid grid-cols-1 xl:grid-cols-2 justify-items-center gap-y-16 my-20">
                    <div className="w-[90%] h-full min-h-[400px]">
                        <iframe width="100%" height="100%" src="https://www.youtube.com/embed/jjg98oURBFA?si=Vbl65gEpnXNIvMC9" title="The 4th and 5th of May in The Netherlands" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                    </div>
                    <div className="w-[90%] h-full min-h-[400px]">
                        <iframe width="100%" height="100%" src="https://www.youtube.com/embed/XLBVc3fo_70?si=emiYdBmTO6o27Mx7" title="6 Things You Need to Know about Tilburg" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                    </div>
                    <div className="w-[90%] h-full min-h-[400px]">
                        <iframe width="100%" height="100%" src="https://www.youtube.com/embed/R6mejoE-9xM?si=vnU9OJ7gbz6Bt29K" title="Dating the Dutch! All the Dutch you need for dating in the Netherlands" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                    </div>
                    <div className="w-[90%] h-full min-h-[400px]">
                        <iframe width="100%" height="100%" src="https://www.youtube.com/embed/pHPTIxBWHyI?si=kBmlewuCKcP_59RB" title="Untranslatable Dutch Words" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                    </div>
                </div>

                <TextTitleSection title={data.articles[0].title} text={data.articles[0].text}/>

                <TextImageSection 
                    title="The Hague vs. Rotterdam: which is the best Dutch city for expats?"
                    text={[<>
                        <a href="https://dutchreview.com/expat/rotterdam-vs-the-hague-best-dutch-city-for-expats/" target="_blank" className="px-btn px-btn-theme">Full article</a>
                    </>]}
                    imageSrc="img/projects/DutchReview/1-1-rotterdam-region.jpeg"
                    transparent={true}
                    frameless={true}
                />

                <TextImageSection 
                    title="5 Weird Statues in the Netherlands"
                    text={[<>
                        <a href="https://dutchreview.com/culture/5-weird-statues-in-the-netherlands/" target="_blank" className="px-btn px-btn-theme">Full article</a>
                    </>]}
                    imageSrc="img/projects/DutchReview/weird-statues-in-the-netherlands.jpeg"
                    left={false}
                    transparent={true}
                    frameless={true}
                />

                <TextImageSection 
                    title="A guide to 11 breathtaking castles and palaces in the Netherlands"
                    text={[<>
                        <a href="https://dutchreview.com/traveling/daytrips/castles-in-the-netherlands/" target="_blank" className="px-btn px-btn-theme">Full article</a>
                    </>]}
                    imageSrc="img/projects/DutchReview/Schermafbeelding-2018-07-19-om-14.39.00.png"
                    transparent={true}
                    frameless={true}
                />

                <TextImageSection 
                    title="Living in Leiden: 5 things to do when you move to Leiden"
                    text={[<>
                        <a href="https://dutchreview.com/featured/living-in-leiden-5-things-to-do-when-you-first-move-to-leiden/" target="_blank" className="px-btn px-btn-theme">Full article</a>
                    </>]}
                    imageSrc="img/projects/DutchReview/DutchReview_Leuven_32.jpeg"
                    left={false}
                    transparent={true}
                    frameless={true}
                />

                <TextImageSection 
                    title="Guide to Leuven: a weekend trip to Belgium!"
                    text={[<>
                        <a href="https://dutchreview.com/traveling/daytrips/guide-to-leuven-a-weekend-trip-to-belgium/" target="_blank" className="px-btn px-btn-theme">Full article</a>
                    </>]}
                    imageSrc="img/projects/DutchReview/leiden-bezienswaardigheden.jpeg"
                    transparent={true}
                    frameless={true}
                />

                <TextImageSection 
                    title="Dutch swear words: the guide to insults and cursing in the Netherlands"
                    text={[<>
                        <a href="https://dutchreview.com/culture/dutch-swear-words/" target="_blank" className="px-btn px-btn-theme">Full article</a>
                    </>]}
                    imageSrc="img/projects/DutchReview/DutchReview_Dutch-swearword-kaaskop-1024x1024.jpeg"
                    left={false}
                    small={true}
                    transparent={true}
                    frameless={true}
                />

                <TextImageSection 
                    title="Growth"
                    text={[<>
                        <span className="block">At DutchReview, we placed a high emphasis on our social media pages, especially Facebook and Instagram. Through our amazing following on Facebook, we were able to generate around 100 reactions and 10 comments per post. </span>
                        <span className="block mt-4">On Instagram, our strategy focused on user-generated content, inspiring people to share beautiful photos of the Netherlands through our special hashtag. On average, we received 20 photos a day!</span>
                        <span className="block mt-4 font-black">The biggest milestone was reaching 10K followers on Instagram, which unlocked the "Swipe Up" feature in our stories.</span>
                    </>]}
                    imageSrc="img/projects/DutchReview/dutchreview-ig.png"
                    transparent={true}
                    frameless={true}
                />
                <FactCard data={data.stats} />
            </div>
        </div>
    )
}
