import ChallengeSchoolData from "../../data/projects/challengeSchool.json"
import Title from "../../components/ProjectPages/Title"
import Section from "../../components/ProjectPages/Section"
import SocialLinks from "../../components/ProjectPages/SocialLinks";
import ImagesSection from "../../components/ProjectPages/ImagesSection";
import TextImageSection from "../../components/ProjectPages/TextImageSection";
import TextTitleSection from "../../components/ProjectPages/TextTitleSection";
import FactCard from "../../components/ProjectPages/FactCard";
/*--------------------
* Building AIHR
----------------------*/
export default function AIHR() {
    var data = JSON.parse(JSON.stringify(ChallengeSchoolData));
    data = data[0];
    return (
        <div className="bg-orange-50 h-full pt-[65px] pb-[69px] px-4 md:px-auto w-full">
            <div className="md:w-3/4 w-full mx-auto">
                <Title title={data.job_title + " at " + data.company + "!"} />
                <Section title="Quick intro" content={data.intro}/>
                <div className="text-center my-6">
                    {
                        data.social_links.map((val, i)=>{
                            return <SocialLinks key={i} link={val.link} icon={val.icon}/>
                        })
                    }
                </div>

                <ImagesSection> 
                    {
                        data.carousels_images.map((val, i)=>{
                            return <img key={i} src={val} title="image" alt="image" />
                        })
                    }
                </ImagesSection>
                <Section title={data.growth[0].title}content={data.growth[0].text}/>
            </div>
        </div>
    )
}
