import InsightTVData from "../../data/projects/insightTV.json"
import Title from "../../components/ProjectPages/Title"
import Section from "../../components/ProjectPages/Section"
import SocialLinks from "../../components/ProjectPages/SocialLinks";
import ImagesSection from "../../components/ProjectPages/ImagesSection";
import TextImageSection from "../../components/ProjectPages/TextImageSection";
import TextTitleSection from "../../components/ProjectPages/TextTitleSection";
import FactCard from "../../components/ProjectPages/FactCard";
/*--------------------
* Building InsightTV
----------------------*/
export default function InsightTV() {
    var data = JSON.parse(JSON.stringify(InsightTVData));
    data = data[0];
    return (
        <div className="bg-orange-50 h-full pt-[65px] pb-[69px] px-4 md:px-auto w-full">
            <div className="md:w-3/4 w-full mx-auto">
                <Title title={data.job_title + " at " + data.company + "!"} />
                <Section title="Quick intro" content={data.intro}/>
                <div className="text-center my-6">
                    {
                        data.social_links.map((val, i)=>{
                            return <SocialLinks key={i} link={val.link} icon={val.icon}/>
                        })
                    }
                </div>
                <div className="text-gray-900 p-6 my-6 text-center mt-20">
                    <div className="text-3xl md:text-4xl font-bold">Talent Management and Competitions</div>
                    <p className="text-[16px] md:text-xl mt-6">
                        Our mission was to bring every unique story to life with creative and captivating assets. Through brainstorming and collaborating across departments, we were able to shine a spotlight on each show and the inspiring stories it told.
                    </p>
                    <p className="text-[16px] md:text-xl mt-6">
                        Among our biggest successes were Travel with a Goat, Speed Seekers with Franco Morbidelli, Car Crews with Supercar Blondie, Streetkings in Jail with Ruud Gullit, Epic Exploring with Cody and Josh, Story of Masters with Dan Fogler, and Modern Day Gladiators. 
                    </p>
                    <p className="text-[16px] md:text-xl mt-6">
                        As the Social Media, PR, and Marketing assistant, I was responsible for selecting photography and video snippets to post on social media, editing content to fit the sizing of each platform, creating a posting schedule, and writing the copy for each post. Additionally, I maintained communication with show talent to ensure they promote the show across their personal social media at the right time and provided them with personalized scheduling for their own promotion.
                    </p>
                </div>
                <TextImageSection 
                    title="" 
                    text={[<>
                        <span className="block">Watch the grand season finale of Epic Exploring today only on Insight.TV!</span>
                        <span className="block"><br />Episode Ten sees @exploringwithjosh & @exploringwithcody reunited in the nuclear disaster zone of Fukushima Japan. There, they look back at their adventures and reveal the result of their rankings of locations.</span>
                        <br /><a href="https://www.instagram.com/p/B52KdS7g9pO/?hl=en" target="_blank" className="px-btn px-btn-theme">Full carousel</a>
                    </>]}
                    imageSrc="img/projects/Insight TV/Screenshot 2023-03-29 at 1.11.52 PM.png"
                    small={true}
                    transparent={true}
                    frameless={true}
                />
                <TextImageSection 
                    title="" 
                    text={[<>
                        <span className="block">Happy Monday #SpeedSeekers! Here’s your weekly dose of #MondayMotivation courtesy of @frankymorbido a MotoGP2 World Champion and @motogp’s rising young star! 🏍</span>
                        <span className="block"><br />— “I just chase the things that make me happy”. Are you chasing the things that make you happy? Franco Morbidelli certainly has. From humble beginnings to losing loved ones, to dealing with the pressures of being Valentino Rossi’s young protégés, Franco has the world at his feet! 🏁🏆</span>
                        <br /><a href="https://www.instagram.com/p/BulCk2aIVLo/?hl=en" target="_blank" className="px-btn px-btn-theme">Full GIF</a>
                    </>]}
                    imageSrc="img/projects/Insight TV/Screenshot 2023-03-29 at 1.22.37 PM.png"
                    small={true}
                    left={false}
                    frameless={true}
                />
                <TextImageSection 
                    title="" 
                    text={[<>
                        <span className="block">Watch episode four of Streetkings in Jail now only on Insight.TV! ⚽️</span>
                        <span className="block"><br />Think you know you everything about @ruudgullit? What year did he win the European Cup? Find out with our exclusive Streetkings in Jail stat cards!</span>
                        <br /><a href="https://www.instagram.com/p/B3GqaiUDiaX/?hl=en" target="_blank" className="px-btn px-btn-theme">Full carousel</a>
                    </>]}
                    imageSrc="img/projects/Insight TV/Screenshot 2023-03-29 at 1.35.45 PM.png"
                    small={true}
                    transparent={true}
                    frameless={true}
                />
                <TextImageSection 
                    title="" 
                    text={[<>
                        <span className="block">Road trip around the 🇺🇸with @supercarblondie in her first ever TV series & meet the world's hottest car crews! 🏎️ Our brand new TV series 'Car Crews with Supercar Blondie' premieres August 22 only on Insight.TV! 🇺🇸</span>
                        <br /><a href="https://www.instagram.com/p/B04_cYUDiQ7/?hl=en" target="_blank" className="px-btn px-btn-theme">Full Trailer</a>
                    </>]}
                    imageSrc="img/projects/Insight TV/Screenshot 2023-03-29 at 1.41.02 PM.png"
                    small={true}
                    left={false}
                    frameless={true}
                />
                <TextImageSection 
                    title="" 
                    text={[<>
                        <span className="block">Gamers are the modern-day rock stars – would you agree?</span>
                        <span className="block"><br />Learn how E-Sports brought fame and fortune to many, who never even dreamt of it. Is it possible to make 300.000 dollars just in one weekend?</span>
                        <span className="block"><br />Find out now! Watch Modern day Gladiators on Insight.TV</span>
                        <br /><a href="https://www.instagram.com/p/B5r2psxA_Op/?hl=en" target="_blank" className="px-btn px-btn-theme">Full GIF</a>
                    </>]}
                    imageSrc="img/projects/Insight TV/Screenshot 2023-03-29 at 1.43.03 PM.png"
                    small={true}
                    transparent={true}
                    frameless={true}
                />
                <TextImageSection 
                    title="" 
                    text={[<>
                        <span className="block">July is all about sun, adventure and of course Insight TV Summer Film Fest ☀️🌊 Get ready for a selection of amazing summer movies that will inspire you and make your heart race ❤️ Go to Insight.TV now and get your summer vibe on 🏝</span>
                        <br /><a href="https://www.instagram.com/p/Bzcw6ONIwpG/?hl=en" target="_blank" className="px-btn px-btn-theme">Full GIF</a>
                    </>]}
                    imageSrc="img/projects/Insight TV/Screenshot 2023-03-29 at 1.55.31 PM.png"
                    left={false}
                    small={true}
                    transparent={true}
                    frameless={true}
                />
                <TextImageSection 
                    title="" 
                    text={[<>
                        <span className="block">Insight TV Summer Film Fest is getting dangerous with Droners Volcano Mission 🌋 Watch a team of daredevil DRONERS go on an epic mission to an active volcano to try and fly their drones inside a lava pit - all in the name of science, of course 🧬 Watch their journey now only on Insight.TV 🛸</span>
                        <br /><a href="https://www.instagram.com/p/Bz7kYieocxl/?hl=en" target="_blank" className="px-btn px-btn-theme">Full GIF</a>
                    </>]}
                    imageSrc="img/projects/Insight TV/Screenshot 2023-03-29 at 1.56.37 PM.png"
                    small={true}
                    transparent={true}
                    frameless={true}
                />

                <TextImageSection 
                    title="" 
                    text={[<>
                        <span className="block">Travel With A Goat. This provocative new show challenges international foodies to travel cross country in Africa, South America, Italy, Thailand and Bulgaria. However, there’s a catch – these foodies have to travel with an animal that is destined for the slaughterhouse. They travel with the animal for over four days and have to make the decision at the end of the journey on whether the animal will be killed, or if they will allow it to live. Freedom or Feast: What would you do? Watch the provocative new show, Travel With A Goat, exclusively on Insight.TV. Watch the first episode of Travel With A Goat for free on Insight.TV, then subscribe for only 9.99 per year and get exclusive access to episodes two and three, plus all of our premium content!</span>
                        <br /><a href="https://www.youtube.com/watch?v=n8xevy9Jtv8&ab_channel=InsightTV" target="_blank" className="px-btn px-btn-theme">Full Video</a>
                    </>]}
                    imageSrc="img/projects/Insight TV/Screenshot 2023-03-29 at 1.14.10 PM.png"
                    left={false}
                    small={true}
                    transparent={true}
                    frameless={true}
                />
                <TextImageSection 
                    title="" 
                    text={[<>
                        <span className="block">Dan Fogler's adventure on Story of Masters premieres next Thursday!</span>
                        <span className="block"> <br />Studded with legendary names and mythical figures, Story of Masters takes viewers on an adventure around the world and into the gyms, arenas and dojos of the individuals on the forefront of martial arts past and present. Whether they uphold traditions or tear them down to create something new</span>
                        <span className="block"> <br />- they’ve added their chapter, and Dan’s on a mission to find out what it is.</span>
                        <br /><a href="https://www.youtube.com/watch?v=MF1gPA1sp98&ab_channel=InsightTV" target="_blank" className="px-btn px-btn-theme">Full Video</a>
                    </>]}
                    imageSrc="img/projects/Insight TV/Screenshot 2023-03-29 at 1.48.08 PM.png"
                    small={true}
                    transparent={true}
                    frameless={true}
                />
                <TextImageSection 
                    title="" 
                    text={[<>
                        <span className="block">#InsightTVRewind is mixing things up today with a special Epic Exploring surprise! Get a taste of what filming for our adventurous new show was like with Exploring with Josh & Exploring With Cody! You’ve never seen anything like this before! #wow</span>
                        <br /><a href="https://youtu.be/XxKq3sco13Y" target="_blank" className="px-btn px-btn-theme">Full Video</a>
                    </>]}
                    imageSrc="img/projects/Insight TV/Screenshot 2023-03-29 at 1.50.39 PM.png"
                    left={false}
                    small={true}
                    transparent={true}
                    frameless={true}
                />
                <Section title={data.talent[0].title} content={data.talent[0].text}/>
                <TextImageSection 
                    title={[<>
                        <span className="block">Epic Exploring competition:</span>
                        <span className="block"> <br />Epic trip to Bulgaria with Josh and Cody!</span>
                        <br /><a href="https://www.instagram.com/p/B3mh8T1joub/?hl=en" target="_blank" className="px-btn px-btn-theme">Full Competition Post</a>
                    </>]}
                    text=""
                    imageSrc="img/projects/Insight TV/Screenshot 2023-03-29 at 12.46.56 PM.png"
                    small={true}
                    transparent={true}
                    frameless={true}
                />
                <TextImageSection 
                    title={[<>
                        <span className="block">Car Crews with SuperCar Blondie competition:</span>
                        <span className="block"> <br />Win a trip to Dubai!</span>
                        <br /><a href="https://www.instagram.com/p/B10pIZ9gdUc/?hl=en" target="_blank" className="px-btn px-btn-theme">Full Competition Post</a>
                    </>]}
                    text=""
                    imageSrc="img/projects/Insight TV/Screenshot 2023-03-29 at 12.47.17 PM.png"
                    left={false}
                    small={true}
                    transparent={true}
                    frameless={true}
                />
                <TextImageSection 
                    title={[<>
                        <span className="block">Streetkings in Jail Giveaway:</span>
                        <span className="block"> <br />Win a signed jersey by Ruud Gullit!</span>
                        <br /><a href="https://www.instagram.com/p/B3E5P75DRME/?hl=en" target="_blank" className="px-btn px-btn-theme">Full Competition Post</a>
                    </>]}
                    text=""
                    imageSrc="img/projects/Insight TV/Screenshot 2023-03-29 at 12.46.39 PM.png"
                    small={true}
                    transparent={true}
                    frameless={true}
                />
                <TextImageSection 
                    title="Production Assistance"
                    text="I had the exciting opportunity to collaborate on the production of Jndia: The Fast Life. For four intense days, I was on-site in the UK for a race tournament to capture all the necessary footage and assets, including photos, promo videos, and interviews. It was a fast-paced environment, and I was responsible for ensuring that the script was followed to generate the best possible results."
                    imageSrc="img/projects/Insight TV/1636534052704.jpeg"
                    left={false}
                    small={true}
                    transparent={true}
                    frameless={true}
                />

                <div className="grid grid-cols-1 xl:grid-cols-2 justify-items-center gap-x-12 gap-y-4 my-20">
                    <div className="w-[90%] h-full min-h-[400px]">
                        <iframe width="100%" height="100%" src="https://www.youtube.com/embed/Ay1bXealSQE?si=RmvwnjnJ_6Wq_-4a" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                    </div>
                    <div className="w-[90%] h-full min-h-[400px]">
                        <iframe width="100%" height="100%" src="https://www.youtube.com/embed/wFoEsxBayEo?si=Swh1cTiMAGqmNJBF" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                    </div>
                </div>
                
                <TextImageSection 
                    title="Collaboration with Industry Leaders"
                    text="During my time at Insight TV, I had the privilege of collaborating with Vice Media on several noteworthy documentary projects. Additionally, I was responsible for creating visually striking designs for a variety of print ads, invites, and digital banners for high-profile publications such as Variety, WorldScreen, and C21. This allowed me to demonstrate my ability to effectively execute creative endeavors while maintaining a keen eye for detail and design."
                    imageSrc="img/projects/Insight TV/magazine-mockup.png"
                    small={true}
                    transparent={true}
                    frameless={true}
                />
                <ImagesSection> 
                    {
                        data.carousels_images.map((val, i)=>{
                            return <img key={i} src={val} className="mix-blend-multiply w-auto h-80" title="image" alt="image" />
                        })
                    }
                </ImagesSection>
                <TextImageSection 
                    title="Growth"
                    text={[<>
                        <span className="block">Working with influencers at Insight TV, we achieved remarkable growth in our subscriber base by leveraging their reach and engagement. Our main platform, Instagram, was the hub of our success and we used a variety of tactics including collaborations with our show talent, competitions, and eye-catching visuals to create content that truly resonated with our audience.</span>
                        <span className="block mt-4 font-black"> My biggest win was growing the company's Instagram following from 9000 to 20,000.</span>
                    </>]}
                    imageSrc="img/projects/Insight TV/gbfdvcsdfg.png"
                    small={true}
                    transparent={true}
                    frameless={true}
                />
                <FactCard data={data.stats} />
            </div>
        </div>
    )
}
