
export default function FactCard(props) {
    return (
        <div className="flex flex-col lg:flex-row gap-4 justify-evenly my-10">
            {
                props.data.map((val, i)=>{
                    return  <div key={i} className="border-4 pt-5 pb-[25px] px-[30px] rounded-[5px] border-solid border-[#ddd] lg:w-full">
                        <h3 className="text-[#34495e] inline-block text-[40px] font-semibold relative m-0 p-0 border-[none]">{val.value}</h3>
                        <p className="text-base leading-[26px] relative pl-[45px] m-0 uppercase before:absolute before:h-1 before:w-4 before:left-5 before:top-[11px] before:bg-[#34495e]">
                            {val.text}
                        </p>
                    </div>
                })
            }
        </div>
    );
}
