
import { useState } from "react";
import { Link } from "react-scroll";

/*--------------------
* Header Menu
----------------------*/
function Menu(props){
    return (
        <li><Link data-scroll-nav={props.id} smooth={true} to={props.link} onClick={() => props.state(false)}><span>{props.name}</span></Link></li>
    );
}

/*--------------------
* Header
----------------------*/
export default function Header() {
    const [isOpenMenu, setIsOpenMenu] = useState(false);
    const header_menus = [
        { id:0, name:'Home', scroll_link:'home' },
        { id:1, name:'About', scroll_link:'about' },
        { id:2, name:'Services', scroll_link:'services' },
        { id:3, name:'Tools', scroll_link:'tool' },
        { id:4, name:'Portfolio', scroll_link:'portfolio' },
        { id:5, name:'Testimonial', scroll_link:'testimonial' },
        { id:6, name:'Photography', scroll_link:'photography' },
        { id:7, name:'Contact', scroll_link:'contact' }
    ]
    return (
        <>
            <header className="main-header fixed left-0 right-0 z-[111]">
                <div className="container flex items-center justify-between one-page-nav relative py-5 lg:py-3">
                    <div className="logo">
                        <Link className="text-black font-[600] text-[30px] leading-[1] uppercase tracking-widest hover:cursor-pointer" smooth={true} to="home">Veronika</Link>
                    </div>
                    <button className="lg:hidden mobile_toggle w-[40px] flex flex-col" onClick={() => setIsOpenMenu(!isOpenMenu)}>
                        <span className="w-[25px] h-[2px] bg-slate-900 inline-block"></span>
                        <span className="w-[25px] h-[2px] bg-slate-900 inline-block my-[5px]"></span>
                        <span className="w-[25px] h-[2px] bg-slate-900 inline-block"></span>
                    </button>
                    <div className={`navbar-collapse flex ${isOpenMenu ? 'menu-open' : ''}`}>
                        <ul id="nav" className="navbar lg:mx-auto flex flex-col lg:flex-row p-4 lg:p-0" >
                            {
                                header_menus.map((val, i) => {
                                    return <Menu key={i} id={val.id} name={val.name} link={val.scroll_link} state={setIsOpenMenu}/>
                                })
                            }
                        </ul>
                    </div>
                    <div className="ms-auto hidden lg:flex">
                        <Link className="px-btn px-btn-theme" data-scroll-nav="4" smooth={true} to="contact">Let’s talk 🤳</Link>
                    </div>
                </div>
            </header>
        </>
    )
}
