

export default function  TextImageSection({ title, text, imageSrc, left = true, small = false, transparent = false, frameless = false, video = false }) {
    return (
        <div className="xl:my-28 lg:my-14 my-6 grid grid-cols-12 gx-3 portfolio-box justify-around items-center">
            <div className={ (left ? "md:order-last " : "") + (small ? "lg:col-span-4" : "lg:col-span-6") + " col-span-12 md:px-5 lg:px-10 mb-10 md:mb-0"}>
                <div className={frameless ? "" : "portfolio-img"}>
                    {video 
                        ?   <iframe width="100%" height="315" src={imageSrc} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                        :   <img className={transparent && "mix-blend-multiply"} src={imageSrc} title={title} alt="" />
                    }
                </div>
            </div>
            <div className={(small ? "lg:col-span-8" : "lg:col-span-6") + " col-span-12 md:px-5 lg:px-10"}>
                <div className="portfolio-text mb-6">
                    <div className="font-black text-3xl mb-3 xl:text-5xl">{title}</div>
                    <p className="text-lg xl:text-2xl">{text}</p>
                </div>
            </div>
        </div>
    );
}


