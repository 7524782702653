import Icon from "../UI/Icon";
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';

import { Navigation, Autoplay } from 'swiper/modules';

import socialMediaXP from "../../data/socialMediaXP.json";
import { Tooltip as ReactTooltip } from "react-tooltip";
/*--------------------
* Brand
----------------------*/
function Brand(props){
    return(
        <>
            <div className="p-3 text-center d-flex align-items-center justify-content-center w-auto" data-tooltip-id="socialMediaXpTooltip">
                <Icon 
                    icon={props.icon}
                    iconStyle="fab" 
                    className="w-12 h-12 font-black text-[#FEC447] xl:text-2xl lg:text-xl text-md px-3 py-1"
                />
            </div>
        </>
    )
}

/*--------------------
* Brand Slider
----------------------*/
export default function BrandSlider() {
    return (
        <section className="section bg-slate-900 py-8">
            <Swiper 
                modules={[Navigation, Autoplay]}
                navigation={true}
                rewind={true}
                slidesPerView={5}
                autoplay={{
                    delay: 2500,
                }}
                breakpoints={{
                    0: {
                        slidesPerView: 1,
                    },
                    819:{
                        slidesPerView:3,
                    },
                    1024:{
                        slidesPerView:4,
                    }
                }}
            >
                {
                    socialMediaXP.map((val, i)=>{
                        return <SwiperSlide key={i}><Brand title={val.title} icon={val.icon}/></SwiperSlide>
                    })
                }
            </Swiper>
            <ReactTooltip
                id="socialMediaXpTooltip"
                place="top"
                content="Social Media Experience"
                style={{ fontWeight: "900", fontSize: "22px", backgroundColor: "#FEC447"}}
            />
        </section>
    )
}
