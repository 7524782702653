import tools from "../../data/tools.json"
/*--------------------
* Skill Section
----------------------*/
function Skill(props) {
    const bgColours = ["bg-1", "bg-2", "bg-3", "bg-4", "bg-5", "bg-6"];
    var bg = bgColours[Math.floor(Math.random()*bgColours.length)];

    return (
        <>
            <div className="md:col-span-4 sm:col-span-6 col-span-12">
                <div className={bg + " feature-box-02"}>
                    <div className="icon">
                        <img 
                            src={"img/skills/" + props.icon + ".svg"} 
                            alt={props.title} 
                            className="w-10 h-10"></img>
                    </div>
                    <h6>{props.title}</h6>
                </div>
            </div>
        </>
    );
}

/*--------------------
* Skill List Section
----------------------*/
export default function SkillList() {
    return (
        <>
            <section data-scroll-data="3" id="tool" className="section experience-section !pb-0">
                <div className="container">
                    <div className="grid grid-cols-12">
                        <div className="lg:col-span-8 col-span-12 lg:pl-9 z-10">
                            <div className="section-heading">
                                <h3 className="m-0"><span>My Tools</span></h3>
                            </div>
                            <div className="mb-10">
                                <div className="grid gap-4 grid-cols-12">
                                    {
                                        tools.map((val, i)=>{
                                            return <Skill key={i} title={val.title} icon={val.icon}/>
                                        })
                                    }
                                </div>
                            </div>
                        </div>
                        <div className="lg:col-span-4 col-span-12 text-center pt-[40px] md:pt-0 lg:relative">
                            <img className="mx-[auto] lg:absolute lg:bottom-0" src="img/skills-hoody.png" title="" alt=""/>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}
