import Icon from "../UI/Icon"
import { Link } from "react-router-dom";

export default function SocialLinks({ link, icon }) {
    return (
        <Link to={link} target="_blank">
            <Icon icon={icon} iconStyle="fab" className="duration-500 hover:scale-[1.6] w-6 h-6 font-black text-gray-800 xl:text-2xl lg:text-xl text-md px-3 py-1"/>
        </Link>
    );
}
