// Function to import all images
function importAll(r) {
    let images = {};
    r.keys().forEach((item) => { images[item.replace('./', '')] = r(item); });
    return images;
}

// Import all images from the folder
const images = importAll(require.context('../../assets/img/Photography', false, /\.(png|jpe?g|svg)$/));

window.addEventListener('load', function() {
    const scrollers = document.querySelectorAll(".scroller");

    addAnimation();
    // Make an array from the elements within `.scroller-inner`
    const scrollerInner = document.querySelector(".scroller__inner");
    const scrollerContent = Array.from(scrollerInner.children);

    scrollerContent.forEach((item) => {
        const observer = new window.IntersectionObserver(([entry]) => {
            item.animate({
                objectPosition: `0% center`
            }, { duration: 10, fill: "forwards" })

            if (entry.isIntersecting) {
                let viewportOffset = item.getBoundingClientRect();
                let position = 100 - ((viewportOffset.left * 100) / window.innerWidth);
                item.animate({
                    objectPosition: `100% center`
                }, { duration: 17000, fill: "forwards" })
                return
            }
        }, {
            root: null,
            threshold: 0.1, // set offset 0.1 means trigger if atleast 10% of element in viewport
        })
        observer.observe(item);
    });

    function addAnimation() {
        scrollers.forEach((scroller) => {
            // add data-animated="true" to every `.scroller` on the page
            scroller.setAttribute("data-animated", true);

            // Make an array from the elements within `.scroller-inner`
            const scrollerInner = scroller.querySelector(".scroller__inner");
            const scrollerContent = Array.from(scrollerInner.children);

            // For each item in the array, clone it
            // add aria-hidden to it
            // add it into the `.scroller-inner`
            scrollerContent.forEach((item) => {
                const duplicatedItem = item.cloneNode(true);
                duplicatedItem.setAttribute("aria-hidden", true);
                scrollerInner.appendChild(duplicatedItem);
            });
        });
    }
})

const ImageGallery = () => {
    return (
        <div className="scroller relative min-h-[50vmin] h-[300px]" data-direction="left" data-speed="slow">
            <div className="scroller__inner absolute translate-x-0 select-none">
                {Object.keys(images).map((key, index) => (
                    <img key={index} src={images[key]} alt={key} className="md:w-[30vmin] md:h-[43vmin] sm:w-[40vmin] sm:h-[45vmin] w-[60vmin] h-[75vmin] object-cover object-[0%_center] flex-shrink-0"/>
                ))}
            </div>
        </div>
    );
};

export default ImageGallery;
