// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';

import { Navigation, Autoplay } from 'swiper/modules';

import workWith from "../../data/workWith.json";
import capitalise from "../../Utils/capitalise";
import { Tooltip as ReactTooltip } from "react-tooltip";

/*--------------------
* Brand
----------------------*/
function Brand(props){
    return(
        <>
            <div className="p-3 text-center d-flex align-items-center justify-content-center w-auto">
                <span 
                    className="font-black text-[#FEC447] xl:text-2xl lg:text-xl text-md px-3 py-1 border border-[#FEC447] rounded-md" 
                    data-tooltip-id="swiperTooltip"
                >
                        {capitalise(props.title)}
                </span>
            </div>
        </>
    )
}

/*--------------------
* Brand Slider
----------------------*/
export default function BrandSlider() {
    return (
        <>
            <div  className="bg-slate-900 py-8">
                <div className="">
                    <Swiper 
                        modules={[Navigation, Autoplay]}
                        navigation={true}
                        rewind={true}
                        slidesPerView={4}
                        autoplay={{
                            delay: 2500,
                        }}
                        breakpoints={{
                            0: {
                                slidesPerView: 1,
                            },
                            992:{
                                slidesPerView:2,
                            },
                            1024:{
                                slidesPerView:3,
                            }
                        }}
                    >
                        {
                            workWith.map((val, i)=>{
                                return <SwiperSlide key={i}><Brand title={val.title}/></SwiperSlide>
                            })
                        }
                    </Swiper>
                </div>
            </div>
            <ReactTooltip
                id="swiperTooltip"
                place="top"
                style={{ fontWeight: "900", fontSize: "22px", backgroundColor: "#FEC447"}}
                content="Drag for more!"
            />
        </>
    )
}
