// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';

import { Navigation, Pagination, Scrollbar, A11y, Autoplay } from 'swiper/modules';

import testimonials from "../../data/testimonial.json";


/*--------------------
* Record
----------------------*/
function Record(props){
    return(
        <div className="flex sm:mx-32 m-4 justify-evenly">
            <div className="feature-box-03 max-w-5xl">
                <div className="feature-content">
                    <div className="icons">
                        <i className="fas fa-quote-left"></i>
                    </div>
                    <p className='text-justify'>{ props.description }</p>
                    <div className='text-3xl text-left font-black mt-6'>{ props.author_name }</div>
                    <div className='text-xl text-left'>{ props.position }</div>
                </div>
            </div>
        </div>
    )
}

/*--------------------
* Testimonial Section
----------------------*/
export default function Testimonial() {
    return (
        <section data-scroll-data="5" id="testimonial" className="sm:section testimonial-section sm:mb-10 mt-20">
            <div className="grid section-heading">
                <div className="lg:col-span-6 text-center mx-auto">
                    <h3><span>Testimonial</span></h3>
                </div>
            </div>
            <Swiper 
                spaceBetween={50} 
                navigation={true}
                rewind={true}
                slidesPerView={2}
                autoplay={{
                    delay: 12500
                }}
                modules={[Navigation, Pagination, Scrollbar, A11y, Autoplay]}
                pagination={{ clickable: true }}
                breakpoints={{
                    0: {
                    slidesPerView: 1,
                    },
                    992:{
                    slidesPerView:2,
                    },
                }}
            >
                {
                    testimonials.map((val ,i) => {
                        return <SwiperSlide key={i}>
                            <Record 
                                author_name = {val.author_name} 
                                description = { val.description }
                                position = { val.position }
                            />
                        </SwiperSlide>
                    })
                }
            </Swiper>
        </section>
    );
}
