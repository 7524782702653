import LearnWorksData from "../../data/projects/learnWorks.json"
import Title from "../../components/ProjectPages/Title"
import Section from "../../components/ProjectPages/Section"
import SocialLinks from "../../components/ProjectPages/SocialLinks";
import ImagesSection from "../../components/ProjectPages/ImagesSection";
import TextImageSection from "../../components/ProjectPages/TextImageSection";
import TextTitleSection from "../../components/ProjectPages/TextTitleSection";
import FactCard from "../../components/ProjectPages/FactCard";
/*--------------------
* Building LearnWorks
----------------------*/
export default function LearnWorks() {
    var data = JSON.parse(JSON.stringify(LearnWorksData));
    data = data[0];
    return (
        <div className="bg-orange-50 h-full pt-[65px] pb-[69px] px-4 md:px-auto w-full">
            <div className="md:w-3/4 w-full mx-auto">
                <Title title="Social Media Specialist at LearnWorlds!" />
                <Section title="Quick intro" content={data.intro}/>
                <div className="text-center my-6">
                    {
                        data.social_links.map((val, i)=>{
                            return <SocialLinks key={i} link={val.link} icon={val.icon}/>
                        })
                    }
                </div>
                <Section className="mt-20" title="Carousels" content={data.carousels}/>
                <ImagesSection> 
                    {
                        data.carousels_images.map((val, i)=>{
                            return <img key={i} src={val} title="image" alt="image" />
                        })
                    }
                </ImagesSection>
                <ImagesSection> 
                    {
                        data.carousels_images2.map((val, i)=>{
                            return <img key={i} src={val} title="image" alt="image" />
                        })
                    }
                </ImagesSection>
                <ImagesSection> 
                    {
                        data.carousels_images3.map((val, i)=>{
                            return <img key={i} src={val} title="image" alt="image" />
                        })
                    }
                </ImagesSection>
                <TextImageSection 
                    title={data.engagement[0].title} 
                    text={data.engagement[0].text} 
                    imageSrc="img/projects/LearnWorlds/Funny Blue Purple Cream Camera Readiness Scale Venn Diagram Chart Instagram Post.png"
                />
                <ImagesSection> 
                    {
                        data.carousels_images4.map((val, i)=>{
                            return <img key={i} src={val} title="image" alt="image" />
                        })
                    }
                </ImagesSection>
                <TextTitleSection title={data.trends[0].title} text={data.trends[0].text}/>
                <TextImageSection 
                    title="" 
                    text={[<>
                        <span className="block">Just one click away from a career change?</span>
                        <span className="block"><br />Let the meme war begin!</span>
                        <span className="block"><br />#GOAT𓃵 #Messi #Ronaldo #ChessMeme #FifaWorldCup #BlackFriday</span>
                    </>]}
                    imageSrc="img/projects/LearnWorlds/Messi-and-Ronaldo-BF.png"
                />
                <TextImageSection 
                    title="" 
                    text={[<>
                        <span className="block">Online Course Creator you are? Do. Or do not. There is no try. 💪</span>
                        <span className="block"><br />Course creators are the unsung heroes of the internet. They take on the challenge of building online courses that teach legions of brave students new skills and advance their careers into the galaxy 🚀. They often work behind the scenes, without much recognition. But today we're going to celebrate our #eLearning Jedi Masters - #MayThe4thBeWithYou 🙌</span>
                        <span className="block"><br />#StarWarsDay #eLearning #CourseCreators</span>
                    </>]}
                    imageSrc="img/projects/LearnWorlds/e-learning-jedi.png"
                    left={false}
                />
                <TextTitleSection title="" text={[<>
                        <span className="block">The most important Unwrapped is HERE! 🎉 From how much coffee you guzzled down to get through your busy days ☕, to how many drafts it took to finally get your video published, the unconventional (but still definitely insightful) LearnWorlds Unwrapped has arrived! 🥳</span>
                        <span className="block"><br />Regardless of how 2022 went down for you, LearnWorlds will wholeheartedly continue to support course creators throughout this process in 2023 and beyond❤️</span>
                        <span className="block"><br />#eLearning #CourseCreator #Edupreneur #OnlineCourse</span>
                    </>]}
                />
                <ImagesSection> 
                    {
                        data.carousels_images5.map((val, i)=>{
                            return <img key={i} src={val} title="image" alt="image" />
                        })
                    }
                </ImagesSection>
                <TextImageSection 
                    title={data.infographics[0].title} 
                    text={data.infographics[0].text} 
                    imageSrc="img/projects/LearnWorlds/Infographic/Light Soft Peach Brown Business Marketing Plan Tips Instagram Post.png"
                />
                <ImagesSection> 
                    {
                        data.carousels_images6.map((val, i)=>{
                            return <img key={i} src={val} title="image" alt="image" />
                        })
                    }
                </ImagesSection>
                <TextTitleSection title={data.video_briefs[0].title} text={data.video_briefs[0].text}/>
                <TextImageSection 
                    title="" 
                    text={[<>
                        <span className="block">Have you ever thought of turning your content into an online course❓ Eliza Cani, Learning Designer at LearnWorlds, gives you 6 reasons why creating an online course is a great way to increase your revenue as a business owner, coach, consultant, or other service provider.</span>
                        <span className="block"><br />In this video we go through the first three “Whys” to shatter any doubts:</span>
                        <span className="block"><br />🌎Make an impact by reaching a wider audience</span>
                        <span className="block"><br />💰Earn a passive income</span>
                        <span className="block"><br />💡Easy Management</span>
                        <span className="block"><br />Make sure to check out the rest in our next video ⏭️</span>
                        <span className="block"><br />#Elearning #OnlineCourse #Edupreneur</span>
                        <br /><a href="https://www.instagram.com/reel/Caruoa4D6A5/" target="_blank" className="px-btn px-btn-theme">Full video</a>
                    </>]}
                    imageSrc="img/projects/LearnWorlds/Screenshot-2023-03-30-at-4.21.38-PM.png"
                    small={true}
                />
                <TextImageSection 
                    title="" 
                    text={[<>
                        <span className="block">We asked Artemis Karadimas, Customer Success manager at LearnWorlds, what's the best way to create a successful online learning community 👉</span>
                        <span className="block"><br />Building a community is not an easy thing to achieve, but if done correctly, can be a significant sales driver!</span>
                        <span className="block"><br />💡 Try an 'Introduce yourself' thread 💡</span>
                        <span className="block"><br />💡 Try setting up a Q&A! 💡</span>
                        <span className="block"><br />💡 Try a discussion board! 💡</span>
                        <span className="block"><br />💡 Open a blog as an extension of your course! 💡</span>
                        <span className="block"><br />Do you have any suggestions for building your own online community? Drop us your ideas and success stories below 👇</span>
                        <span className="block"><br />#CourseCreator #OnlineCourse #eLearning</span>
                        <br /><a href="https://www.instagram.com/reel/CgMlNdqjD1H/" target="_blank" className="px-btn px-btn-theme">Full video</a>
                    </>]}
                    imageSrc="img/projects/LearnWorlds/Screenshot-2023-03-30-at-4.19.24-PM.png"
                    small={true}
                    left={false}
                />
                <TextTitleSection title={data.articles[0].title} text={data.articles[0].text}/>
                <TextImageSection 
                    title="How To Boost Your Online Business With Social Media Groups and Communities" 
                    text={[<>
                        <a href="https://www.learnworlds.com/social-media-groups-communities/" target="_blank" className="px-btn px-btn-theme">Full article</a>
                    </>]}
                    imageSrc="img/projects/LearnWorlds/image1.jpeg"
                />
                <TextImageSection 
                    title={data.growth[0].title} 
                    text={data.growth[0].text} 
                    imageSrc="img/projects/LearnWorlds/Social-Media-Followers-Infographic-Instagram-Post.png"
                    transparent={true}
                />
                <FactCard data={data.stats} />
            </div>
        </div>
    )
}
