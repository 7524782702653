import ImageGallery from "./ImageGallery";
/*--------------------
* Photography Section
----------------------*/
export default function Photography() { 

    return (
        <>
            <section data-scroll-data="6" id="photography" className="bg-orange-50 pt-10 relative"> 
                <div className="container">
                    <div className="grid section-heading">
                        <div className="lg:col-span-6 text-center mx-auto">
                            <h3><span>Photography</span></h3>
                        </div>
                    </div>
                    <p className="text-[16px] md:text-[18px]">
                        I love honing my photography skills in my free time, exploring the world and all its wonders through the lens of my camera. I'm always on the lookout for interesting and unique subjects to shoot, from my pets and family; to breathtaking sceneries and animals in their natural habitats. Capturing these special moments in time is what drives me to continue pursuing photography.
                    </p>
                </div> 

                <ImageGallery />
            </section> 
        </>
    );
}
