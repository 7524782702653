
/*--------------------
* Footer Section
----------------------*/
function Footer() {
    return (
        <footer className="footer bg-slate-900 border-t border-white border-opacity-10 py-4 fixed bottom-0 left-0 right-0 z-20">
            <div className="container">
                <div className="grid grid-cols-12">
                    <div className="col-span-12 flex pb-3 md:pb-0">
                        <div className="flex !justify-center md:justify-start w-full">
                            <a id="insta" className="text-white text-opacity-90 hover:rounded-md px-1 mr-5 text-[16px]" target="_blank" href="https://www.instagram.com/miss.veronicat/"><i className="fab fa-instagram"></i></a>
                            <a className="text-white text-opacity-90 hover:bg-[#0A66C2] hover:rounded-md px-1 mr-5 text-[16px]" target="_blank" href="https://www.linkedin.com/in/veronika-licheva/"><i className="fab fa-linkedin-in"></i></a>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    );
}

export default Footer;