import Icon from "../UI/Icon"
import workWith from "../../data/workWith.json"
import capitalise from "../../Utils/capitalise";
/*--------------------
* Service Section
----------------------*/
function Service(props) {
    const bgColours = ["bg-1", "bg-2", "bg-3", "bg-4", "bg-5", "bg-6"];
    var bg = bgColours[Math.floor(Math.random()*bgColours.length)];
    
    return (
        <>
            <div className={bg + " feature-box-01"}>
                <Icon icon={props.icon} className="w-12 h-12 p-2 border-2 border-slate-900 text-slate-900 flex items-center justify-center bg-white text-[32px]"/>
                <div className="feature-content">
                    <h5>{capitalise(props.title)}</h5>
                    <p>{props.description}</p>
                </div>
            </div>
        </>
    );
}

/*--------------------
* Service List Section
----------------------*/
export default function ServiceList() {
    return (
        <>
            <section data-scroll-data="2" id="services" className="section services-section bg-gray" style={{ "backgroundImage": "url(img/effect/bg-effect-1.png)", "backgroundRepeat": "no-repeat", "backgroundSize": "cover"}}>
                <div className="container">
                    <div className="grid section-heading">
                        <div className="lg:col-span-6 text-center mx-auto">
                            <h3><span>My Services</span></h3>
                        </div>
                    </div>
                    <div className="grid lg:grid-cols-2 md:grid-cols-2 gap-5 grid-cols-1 items-stretch">
                        {
                            workWith.map((val, i) => {
                                return <Service key={i} title={val.title} description={val.description} icon={val.icon} />
                            })
                        }
                    </div>
                </div>
            </section>
        </>
    );
}
