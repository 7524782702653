import React, { useRef, useState } from 'react';
import emailjs from '@emailjs/browser';
import Swal from 'sweetalert2';
import ReCAPTCHA from 'react-google-recaptcha';

/*--------------------
* Contact Section
----------------------*/
export default function Contact() {
    const [recaptchaValue, setRecaptchaValue] = useState(null);
    const form = useRef();

    const onCaptchaChange = (value) => {
        setRecaptchaValue(value);
    };

    const handleSubmit = (event) => {
        event.preventDefault();

        if (!recaptchaValue) {
            Swal.fire({
                title: 'Please verify you are a human!',
                icon: 'warning',
                toast: true,
                position: 'bottom-start',
                timer: 5000,
                timerProgressBar: true,
                showConfirmButton: false,
            });
            return;
        }

        // Prevent bots
        if (!event.target.elements.bot.value) {
            emailjs
                .sendForm('service_qymi1np', 'template_qxj0c7q', form.current, {
                    publicKey: 'mh-aIPB7JzEAHcdmH',
                })
                .then(
                    () => {
                        form.current.reset();
                        Swal.fire({
                            title: 'Email sent',
                            text: 'I will get back to you as soon as I can!',
                            icon: 'success',
                            toast: true,
                            position: 'top-end',
                            timer: 5000,
                            timerProgressBar: true,
                            showConfirmButton: false,
                        });
                    },
                    (error) => {
                        Swal.fire({
                            title: 'Email not sent',
                            text: 'Something went wrong with the online form. Please send me a mail manually! My email can be found at the bottom of the page, thank you!',
                            icon: 'error',
                            toast: true,
                            position: 'top-end',
                            timer: 10000,
                            timerProgressBar: true,
                            showConfirmButton: false,
                        });
                        console.log('FAILED...', error.text);
                    },
                );
        };
    }
    


    return (
        <section data-scroll-data="7" id="contact" className="section contact-section bg-slate-900" style={{ "backgroundImage": "url(img/effect/bg-effect-3.png)", "backgroundRepeat": "no-repeat", "backgroundSize": "cover", "backgroundPosition": "center"}}>
            <div className="container">
                <div className="grid grid-cols-12">
                    <div className="col-span-12 lg:col-span-6 ">
                        <div className="p-[25px] md:p-[35px] bg-white">
                            <h6 className="text-[32px] font-[600] text-black mb-[5px]">Get in touch</h6>
                            <form onSubmit={handleSubmit} ref={form}>
                                <input name="bot" id="bot" type="hidden" value="" className="hidden invisible"/>
                                <div className="grid grid-cols-12 gap-3">
                                    <div className="col-span-12 md:col-span-6">
                                        <div className="form-group">
                                            <label className="form-label">First name</label>
                                            <input name="from_name" id="name" placeholder="Name *" className="form-control" type="text" required/>
                                        </div>
                                    </div>
                                    <div className="col-span-12 md:col-span-6">
                                        <div className="form-group">
                                            <label className="form-label">Your Email</label>
                                            <input name="from_email" id="email" placeholder="Email *" className="form-control" type="email" required/>
                                        </div>
                                    </div>
                                    <div className="col-span-12">
                                        <div className="form-group">
                                            <label className="form-label">Subject</label>
                                            <input name="subject" id="subject" placeholder="Subject *" className="form-control" type="text" required/>
                                        </div>
                                    </div>
                                    <div className="col-span-12">
                                        <div className="form-group">
                                            <label className="form-label">Your message</label>
                                            <textarea name="message" id="message" placeholder="Your message *" rows="4" className="form-control" required></textarea>
                                        </div>
                                    </div>
                                    <ReCAPTCHA
                                        sitekey="6LeUIXUUAAAAAIGEco8CpOrFgNoSbHw77lrlKTV0"
                                        onChange={onCaptchaChange}
                                    />
                                    <div className="col-span-12">
                                        <div className="send">
                                            <button className="px-btn px-btn-theme2 sm:w-auto w-full" type="submit"> Send Message 💌</button>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                    <div className="col-span-12 lg:col-span-6 flex">
                        <div className="lg:max-w-[410px] w-full lg:ml-auto py-[50px] lg:pt-0">
                            <div className="pb-10">
                                <img className="w-full" src="img/contact-blazer.png" title="" alt="" />
                            </div>
                            <ul className="contact-infos">
                                <li>
                                    <div className="icon bg-1">
                                        <i className="fas fa-envelope"></i>
                                    </div>
                                    <div className="col">
                                        <h5>Phone</h5>
                                        <p>+359 877610337</p>
                                    </div>
                                </li>
                                <li>
                                    <div className="icon bg-2">
                                        <i className="fas fa-phone-alt"></i>
                                    </div>
                                    <div className="col">
                                        <h5>Mail</h5>
                                        <p>veronika.licheva23@gmail.com</p>
                                    </div>
                                </li>
                                <li>
                                    <div className="icon bg-3">
                                        <i className="fas fa-map-marker-alt"></i>
                                    </div>
                                    <div className="col">
                                        <h5>Base of operations</h5>
                                        <p>Bulgaria</p>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}
