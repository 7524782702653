
import { Link } from "react-scroll";
import calculateXP from "../../Utils/calculateXP";
/*--------------------
* About Section
----------------------*/
export default function About() {
    return (
        <>
            <section className="section" id="about" data-scroll-data="1">
                <div className="container">
                    <div className="grid lg:grid-cols-12 grid-cols-1 items-center">
                        <div className="lg:col-span-6 text-center mb-[50px] lg:mb-0">
                            <img className="mx-auto" src="img/aboutme-blazer.png" title="Banner" alt="Banner" />
                        </div>
                        <div className="lg:col-span-6 lg:pl-12">
                            <h3 className="font-[600] text-[26px] md:text-[40px] leading-[1.2] text-black mb-[25px]">Unlock the power of modern-day storytelling with social media and content creation.</h3>
                            <p className="text-[16px] md:text-[18px]">
                                I am passionate about social media, content creation, and helping companies 
                                convey their brand’s personality to the world.
                                I like working with new people, together, to achieve a collective goal/deadline.
                                If it's one thing I learned over the {calculateXP()} working in this sector is that 
                                there is no such a thing as a wrong question during brainstorming and design. 
                                I believe that through great imagery and messaging any company can create a valuable, 
                                long-lasting relationship with its customers. <span className=" font-black">So why not let me help you with that?</span></p>
                            <div className="grid grid-cols-12 pt-5">
                                <div className="pet col-span-12 xs:col-span-12 px-btn px-btn-dark text-center h-auto">
                                    <span>Mother of  
                                        <span className="font-[900] text-2xl"> 3 </span> 
                                        adorable 
                                        <span className="text-2xl">🐈</span> 
                                        <span className="group balooP relative text-center p-1 ml-4 hover:text-orange-600 hover:bg-white">Baloo
                                            <img className="hidden baloo group-hover:block lg:h-[300px] lg:w-[300px] md:h-[200px] md:w-[200px] sm:h-[150px] sm:w-[150px] xs:h-[100px] xs:w-[100px] fixed bottom-20 sm:left-80 left-64 -translate-x-60 transition-all duration-500" src="img/cats/baloo.png" title="Baloo" alt="Banner" />
                                        </span>
                                        <span className="group cleoP relative text-center p-1 hover:text-orange-600 hover:bg-white">Cleo
                                            <img className="hidden cleo group-hover:block lg:h-[300px] lg:w-[300px] md:h-[200px] md:w-[200px] sm:h-[150px] sm:w-[150px] xs:h-[100px] xs:w-[100px] fixed bottom-20 sm:left-0 left-0 m-auto right-0 transition-all duration-500" src="img/cats/cleo.png" title="Cleo" alt="Banner" />
                                        </span>
                                        <span className="group chiliP relative text-center p-1 hover:text-orange-600 hover:bg-white">Chili
                                            <img className="hidden chili group-hover:block lg:h-[300px] lg:w-[300px] md:h-[200px] md:w-[200px] sm:h-[150px] sm:w-[150px] xs:h-[100px] xs:w-[100px] fixed bottom-20 sm:right-80 right-64 translate-x-60 transition-all duration-500" src="img/cats/chili.png" title="Chili" alt="Banner" />
                                        </span>        
                                    </span>
                                </div>
                            </div>
                            <div className="grid grid-cols-12">
                                <Link className="px-btn px-btn-theme col-span-12 sm:col-span-3 sm:mr-3 mt-3 text-center" smooth={true} to="portfolio">Portfolio 📂</Link>
                                <Link className="px-btn px-btn-dark col-span-12 sm:col-span-4 sm:mr-3 mt-3 text-center" smooth={true} to="contact">Contact Me 💌</Link>
                                <a className="px-btn px-btn-theme col-span-12 sm:col-span-5 mt-3 text-center" download href="cv/Veronika-Licheva-CV.pdf">Download CV 📑</a>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}
