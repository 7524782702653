import Veronika from './components/Origin/veronika'
import { library } from "@fortawesome/fontawesome-svg-core";
import { far } from "@fortawesome/free-regular-svg-icons";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { fab } from '@fortawesome/free-brands-svg-icons';
import { BrowserRouter as Router} from 'react-router-dom';
import Header from './components/Header/header';
import Footer from './components/Footer/footer';

library.add(far, fas, fab);

function App() {
    return (
        <Router>
            <Header/>
            <Veronika />
            <Footer/>
        </Router>
    );
}

export default App;