
export default function VideoSection({ title, children }) {
    return (
        <div className="flex flex-col items-center my-6">
            <h1 className="text-3xl md:text-4xl font-bold text-gray-900 my-6">{title}</h1>
            <div className="grid grid-cols-1 md:grid-cols-3 gap-4 justify-items-center w-full">
                {children}
            </div>
        </div>
    );
}
